import React, { useRef } from 'react';
import { Card as CardComponent, css } from 'theme-ui';
import { Layout, Stack, Main } from '@layout';
import CardList from '@components/CardList';
import Card from '@components/Card';
import Divider from '@components/Divider';
import Seo from '@widgets/Seo';
import AuthorExpanded from '@widgets/AuthorExpanded';
import {
  PostImage,
  PostBody,
  // PostComments,
  PostTagsShare,
} from '@widgets/Post';
import FixedButton from '../../../../content/components/FixedButton';
import { useHasBeenVisible } from '../../../../hooks/useVisibility';
import FullWidthPlaceHolder from '../../../../content/components/shared/FullWidthPlaceHolder';

const COUNTRIES = { it: 'Italia', uk: 'UK', es: 'España', us: 'US', pt: 'Portugal' };
/**
 * <iframe
 * width="560"
 * height="315"
 * src="https://www.youtube.com/embed/3hGcLn5di5w"
 * title="YouTube video player"
 * frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
 */

const getYoutubeEmbedLink = (src) => {
  const url = new URL(src)
  let id = url.searchParams.get('v')
  if(!id) {
    id = url.pathname.split('/').pop()
  }
  return `https://www.youtube.com/embed/${id}`;
}

const Trailer = ({ src }) => (
  <iframe
    style={{
      width: '100%',
      height: 'min( 50vw , 500px )',
      border: '0',
    }}
    src={getYoutubeEmbedLink(src)}>
  </iframe>
)

const Post = (props) => {
  const {
    data: {
      site,
      post,
      tagCategoryPosts,
      tagPosts,
      categoryPosts,
      // previous,
      // next,
    },
  } = props;
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : []),
  ];
  const {
    locale,
    siteUrl,
    app: { baseUrl },
  } = site.siteMetadata;
  const relatedRef = useRef();
  const hasScrolledRelated = useHasBeenVisible(relatedRef);

  const authorRef = useRef();
  const hasScrolledAuthor = useHasBeenVisible(authorRef);
  const fullSiteUrl = `${siteUrl}/${locale}`
  const { pageContext: { services = {} } = {} } = props;
  const content = props?.pageContext?.modifiedData.replaceAll(
    /http[s]?:\/\/app.togetherprice.com/gi,
    baseUrl
  );
  const links = content.match(/"href": "(.*?)"/g);
  const link = links
    ?.filter((link) => link.includes(baseUrl))
    ?.reverse()
    ?.pop()
    ?.replaceAll('"href": "', '')
    ?.replaceAll('"', '');
  const { GATSBY_BUILD_LANGUAGE } = process.env;

  return (
    <Layout {...props}>
      <Seo
        {...post}
        thumbnail={{
          hero: {
            src: post.thumbnail?.ContentfulAsset_fluid?.src,
          },
        }}
        siteUrl={fullSiteUrl}
        baseUrl={baseUrl}
        appended={COUNTRIES[GATSBY_BUILD_LANGUAGE || locale]}
        logo={
          props.data.allBlockContent.nodes.find(
            (item) => item.identifier === 'footer'
          ).images[1]
        }
      >
        <link rel="amphtml" href={fullSiteUrl + post.slug + "amp"} />
      </Seo>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <Card {...post} variant='horizontal-hero' omitExcerpt omitMedia />
        </Main>
      </Stack>
      <Divider space={3} />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper-lg'>
            {post.trailerLink && <Trailer src={post.trailerLink} />}
            {!post.trailerLink && post.thumbnail && <PostImage {...post} inCard />}
            <PostBody body={content} />
            <PostTagsShare {...post} location={props.location} />
            {/* {services.disqus && <PostComments {...post} />} */}
          </CardComponent>
          <Divider />
          
          {hasScrolledAuthor ? (<AuthorExpanded author={post.author} />) : <FullWidthPlaceHolder
                reference={authorRef}
                customStyle={{ height: '230px' }}
              />}
              
          <Divider />
          {post.category && (
            hasScrolledRelated ? (
              <CardList
                title='Related Posts'
                nodes={relatedPosts}
                variant={['horizontal-md']}
                columns={[1, 2, 2, 2]}
                limit={6}
                distinct
              />
            ): (
              <FullWidthPlaceHolder
                reference={relatedRef}
                customStyle={{ height: '820px' }}
              />
            )
          )}
        </Main>
      </Stack>
      {link && <FixedButton href={link} locale={locale} />}
    </Layout>
  );
};

export default Post;
